.event-item-date {
    font-size: 14px !important;
    font-weight: bold !important;
}

.event-item-title {
    font-size: 18px !important;
    font-weight: bold !important;
    color: #ffc919;
}

.event-item-venue {
    margin-top: 12px !important;
    font-size: 14px !important;
}

.event-item-desc {
    font-size: 14px !important;
    margin-top: 12px !important;
    color: gray;
}

.event-heading {
   padding: 20px;
   font-weight: bold !important;
}

.event-box {
    padding: 20px !important;
    margin: 20px;
    cursor: pointer;
    /* transition: 0.3s ease-in-out; */
 }
 .event-box:hover {
    /* transform: scale(1.01); */
    /* transition: 0.2s ease-in-out; */
    
    background-color: rgba(255, 255, 0, 0.2);
 }
 .upEvents{
    display:grid;
    grid-template-columns : repeat(2,1fr);
 }
 
@media screen and (max-width : 990px) {
    .upEvents{
        grid: none;
    }
}