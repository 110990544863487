.footer_container{
    overflow-x: hidden;
}
.footer_section{
/* <<<<<<< HEAD */
    background: #000;
/* ======= */
    background-color: #000;
/* >>>>>>> 6f80ee07c9bef12784afea224620fe54bd8f67bc */
    padding: 20px;
}
.footer_heading{
    font-size: 20px;
}

.f_col-1{
    width: 30% !important;
}
.f_col-2{
    width: 25% !important;
}
.f_col-3,.f_col-4,.f_col-5{
    width: 15% !important;
}
.footerLink{
    text-decoration: underline !important;
}
.footerLink:hover{
    color: peru !important;
}

@media screen and (max-width:990px) {
    .f_col{
        margin: auto;
    }
    .f_col-1{
        width: 50% !important;
    }
    .f_col-2{
        width: 50% !important;
    }
    .f_col-3,.f_col-4,.f_col-5{
        width: 33% !important;
    }
}
@media screen and (max-width:500px) {
    
    .f_col{
        width: 100% !important;
        text-align: left;
    }
    .f_col p{
        line-height: 15px;
    }
}
@media screen and (max-width:900px){
    .footer_media{
        /* padding-bottom: 20px !important; */
    }
    .copyright{
        margin-bottom: 55px ;
    }
}