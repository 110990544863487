.book-condition {
    border: 1px solid #ffc919;
    background-color: #f7edcb;
    padding: 5px;
    vertical-align: middle;
    height: min-content;
    font-weight: bold;
    margin-bottom: 10px;
}

.book-condition-text {
    color: #ffc919;
    font-weight: bold;
    font-size: 15px;
}

.product-title {
    font-weight: bold;
}

.view-more-less:hover {
    font-style: normal;
    text-decoration: underline;
}

.view-more-less-btn {
    padding: 0;
    text-decoration: none;
    text-transform: none;
}

.view-more-less-btn:hover {
    padding: 0;
    background-color: white;
    text-decoration: none;
    text-transform: none;
}

.cart-btn {
    background-color: #ffc919;
}

.image-delievery {
    height: 50px;
}

.image-delievery-box {
    width: 100px;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.image-delievery-title {
    font-size: 15px;
    margin-top: 5px;
}

.book-detail-attribute-box {
    width: 180px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
}

.book-detail-attribute-image{
    height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.book-detail-title-sub{
    font-weight: bold;
    font-size: 14px;
}