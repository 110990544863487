.rec-dot {
  display: none;
}

.rec.rec-arrow-left:hover:enabled,
.rec.rec-arrow-right:hover:enabled {
  background-color: rgb(29, 26, 26);
  box-shadow: 0 0 1px 3px var(--clr-primary-400);
}

.rec.rec-arrow-left,
.rec.rec-arrow-right {
  background-color: rgb(0, 0, 0);
  transition: all 0.3s ease;
  font-size: 1.1rem !important;
  color: white !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  min-width: 1.5rem !important;
  line-height: 1.5rem !important;
}

.rec-arrow:hover:not(:disabled) {
  transform: scale(1.2);
}

.rec-arrow:active:not(:disabled) {
  background-color: rgb(0, 0, 0);
}