.productCart2{
    content: "";
    clear: both;
    display: grid !important;
    grid-template-columns: repeat(3,1fr) !important;
    padding:0;
    margin:0;
}
.ccc{
    max-width: fit-content;
    margin: auto;
}

@media screen and (max-width:1200px) {
    .productCart2{
        display: grid;
        grid-template-columns: repeat(2,1fr) !important;

    }
}
@media screen and (max-width:1000px) {
    .productCart2{
        display: grid;
        grid-template-columns: repeat(2,1fr) !important;

    }
}
@media screen and (max-width:770px) {
    .productCart2{
        grid: none !important;
        justify-content: center;

    }
}