.activeClass {
  background-color: purple !important;
  color: white !important ;
}

.menuToggle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 5%;
}
.toggleIcon {
  cursor: pointer;
}
.genreList {
  color: black !important;
  width: 100% !important;
  display: flex;
  font-size: 20px !important;
}

.lblCartCount {
  font-size: 12px;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
}

.genreList{
    width: 100% !important;
    display: flex;
    padding: 15px !important;
}

